import axios from "axios";
import config from "@/config";

const state = {
    clients: [],
    activeClients: JSON.parse(localStorage.getItem('client')) || {},
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    sale: JSON.parse(localStorage.getItem('client')) ? JSON.parse(localStorage.getItem('client')).sale : null
};

const getters = {
    getClients(state) {
        return state.clients
    },

    getActiveClient(state) {
        return {
            ...state.activeClients,
            cart: state.cart,
            sale: state.sale
        }
    },

    getClientsOnID(state) {

        let obj = {}

        state.clients.forEach(item => {
            obj[item.id] = {
                ...item
            }
        });

        return obj
    },


}

const mutations = {

    setClients(state, data) {
        state.clients = data
    },

    setClient(state, data) {
        state.clients.push({
            ...data,
            cart: null
        })

    },

    setActiveClients(state, data) {
        state.activeClients = data
        localStorage.setItem('client', JSON.stringify(state.activeClients))
    },

    removeActiveClients(state) {
        state.activeClients = {}
        state.sale = null
        localStorage.setItem('client', null)
    },

    cleanCart(state) {
        state.cart = []
    },

    setTocart(state, data) {
        state.cart.push(data)
    },

    loadCartFromClient(state, data) {
        console.log(data)
        state.cart = JSON.parse(data) || []
    },

    setTocartOne(state, data) {

        for (let key in state.cart) {
            if (state.cart[key].id == data.id) {

                if (data.type == 'inc') state.cart[key].count += data.count;
                if (data.type == 'dec') state.cart[key].count -= data.count;

            }
        }


    },

    removeProductCart(state, data) {
        for (let key in state.cart) {
            if (state.cart[key].id == data.id) {
                state.cart.splice(key, 1);
            }
        }

    },

    setCountInCart(state, data) {

        for (let key in state.cart) {
            if (state.cart[key].id == data.id) {
                state.cart[key].count = data.count
            }
        }


    },

    setSale(state, data) {
        state.sale = data
        state.activeClients.sale = data
    },

    setDateActiveOrder(state, data) {
        state.activeClients.date = data.date
    },

    updateClient(state, data) {
        state.clients.forEach((item, index) => {
            if (item.id == data.id) {
                state.clients[index] = {
                    ...item,
                    ...data
                }
            }
        })
    }


};

const actions = {

    fetchClients(context) {
        axios.get(config.api + 'clients')
            .then(response => {
                context.commit('setClients', response.data)
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    },

    fetchClientOrders(context, data) {
        return axios.post(config.api + 'get-orders-by-client', {
            client_id: data.id
        })

    },

    fetchClientID(context, data) {
        return axios.post(config.api + 'client', {
            id: data.id
        })

    },

    findClients(context, data) {
        axios.get(config.api + 'search-client?search=' + data.val)
            .then(response => {
                context.commit('setClients', response.data)
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        context.commit('setClients', [])
                        break;

                    default:
                        console.error(error.response.data.message);
                }
            })
    },

    filterClients(context, data) {
        axios.get(config.api + 'filter-clients?status=' + data.status)
            .then(response => {
                context.commit('setClients', response.data)
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        context.commit('setClients', [])
                        break;

                    default:
                        console.error(error.response.data.message);
                }
            })
    },

    changeActiveClientStore(context, data) {
        context.commit('loadCartFromClient', data.cart)
        context.commit('setActiveClients', data)
    },

    addToCartStore(context, data) {

        axios.post(config.api + 'item-decrease', {
                id: data.id,
                amount: data.count,
            })
            .then(() => {
                context.commit('setTocart', data)
                context.dispatch('productCountDec', data)
                context.dispatch('saveClientCartStorage')
            })
            .catch(error => {
                console.error(error.response.data.error);

                switch (error.response.status) {
                    case 400:
                        alert('Недостаточно товара в базе, обновите страницу для загрузки актуальной инвормации');
                        break;
                    case 404:
                        alert('Товар удален или перемещен в другую категорию ');
                        break;
                }
            })

    },

    changeCountProductOneStore(context, data) {

        const getRoute = (type) => {
            switch (type) {
                case 'inc':
                    return 'item-decrease';
                case 'dec':
                    return 'item-increase';

                default:
                    console.error('Err. not type on changeCountProductOneStore')
            }
        }

        const errorHandler = (error) => {
            console.error(error.response.data.error);

            switch (error.response.status) {
                case 400:

                    if (data.onError) {
                        data.onError()
                    } else {
                        alert('Недостаточно товара в базе, страница будет обнавлена для загрузки актуальной инвормации');
                        window.location.reload()
                    }

                    break;
                case 404:
                    alert('404: Товар удален или перемещен в другую категорию ');
                    break;
                case 429:
                    alert('429: Слишком много запросов к базе');
                    break;
            }
        }

        if (data.type == 'inc') {
            axios.post(config.api + getRoute(data.type), {
                    id: data.id,
                    amount: data.count,
                })
                .then(() => {

                    if (data.onSave) {
                        data.onSave()
                    } else {
                        context.commit('setTocartOne', data)
                        context.dispatch('productCountDec', data)
                        context.dispatch('saveClientCartStorage')
                    }

                })
                .catch(error => {
                    errorHandler(error)


                })
        }

        if (data.type == 'dec') {
            axios.post(config.api + getRoute(data.type), {
                    id: data.id,
                    amount: data.count,
                })
                .then(() => {
                    context.commit('setTocartOne', data)
                    context.dispatch('productCountInc', data)
                    context.dispatch('saveClientCartStorage')
                })
                .catch(error => {
                    errorHandler(error)
                })
        }



    },

    removeFromCartStore(context, data) {


        axios.post(config.api + 'item-increase', {
                id: data.id,
                amount: data.count,
            })
            .then(() => {
                context.commit('removeProductCart', data)
                context.dispatch('productCountInc', data)
                context.dispatch('saveClientCartStorage')
            })
            .catch(error => {
                console.error(error.response.data.error);

                switch (error.response.status) {

                    case 404:

                        if (confirm('Товар из базы удален или перемещен в другую категорию \n Удалить его из корзины?')) {
                            context.commit('removeProductCart', data)
                            context.dispatch('saveClientCartStorage')
                        }

                        break;
                }
            })

    },

    removeActiveClientStore(context, data) {
        context.commit('removeActiveClients', data)
    },

    changeCountInCart(context, data) {
        context.commit('setCountInCart', data)
        context.dispatch('saveClientCartStorage')
    },

    cleanCartStore(context) {
        context.commit('cleanCart', null)
        context.dispatch('saveClientCartStorage')
        context.dispatch('removeActiveClientStore')
    },

    saveClientToLocalStorage() {
        localStorage.setItem('client', JSON.stringify(state.activeClients))
    },

    saveClientCartStorage() {
        localStorage.setItem('cart', JSON.stringify(state.cart));

        axios.post(config.api + 'change-clients-cart', {
                id: state.activeClients.id,
                cart: state.cart.length ? JSON.stringify(state.cart) : JSON.stringify([]),
            })

            .then(() => {
                for (let key in state.clients) {
                    if (state.clients[key].id == state.activeClients.id) {
                        state.clients[key].cart = JSON.stringify(state.cart)
                    }
                }
            })

            .catch(error => {
                console.error(error.response.data.error);

                switch (error.response.status) {

                    case 404:
                        alert('Ошибка при сохранении корзины: Клиент не существует');
                        break;
                }
            })
    },

    changeClientSaleStore(context, data) {
        context.commit('setSale', data)
    },

    createClientOnStore(context, data) {

        axios.post(config.api + 'add-client', {
                name: data.name,
            })

            .then((response) => {

                data.load(response.data)

                context.dispatch('fetchClients')
            })

            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        alert('Ошибка валидации при добавлении нового пользователя');
                        break;
                }
            })
    },

    changeDateOrder(context, data) {
        context.commit('setDateActiveOrder', data)
    },

    saveClientData(context, data) {
        axios.post(config.api + 'update-client/' + data.id, {
                ...data
            })

            .then((response) => {
                data.onload(response)
                context.commit('updateClient', data)
            })

            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        alert('Ошибка валидации при изменении пользователя');
                        break;
                }
            })
    },

    createClient(context, data) {

        axios.post(config.api + 'add-client', data)

            .then((response) => {
                data.load(response.data)
                context.dispatch('fetchClients')
            })

            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        alert('Ошибка валидации при добавлении нового пользователя');
                        break;
                }
            })
    },



};



export default {
    state,
    getters,
    mutations,
    actions,
};